/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
import {
  firebase, transCollection, customersCollection,
} from '@/firebase'
import { set, filterByKey } from 'vuex-intern'
import Vue from 'vue'
import {
  round, multiply, divide, subtract, add,
} from 'mathjs'

// const actionTypes = ['add_penalty', 'refinance']
// const revenueTypes = ['interest', 'penalty', 'closed', 'fee', 'extend_date']
const expenseTypes = ['expense', 'created', 'refinance', 'lost', 'transaction_fee']
const transactionTypes = ['interest', 'penalty', 'closed', 'expense', 'fee', 'extend_date', 'created', 'refinance']

// 按期付息到期還本法 Balloon Mortgage
// 本金平均攤還法 Constant Amortization Mortgage loan，CAM
// 本利均等攤還法 Constant Payment Mortgage loan, CPM
// 還本寬限期法 漸進加付法Gradual Payment Mortgage，GPM

const state = {
  transactions: [],
  customerTransactions: {},
  dailyTransactions: {},
}

const getters = {
  getTransactions: state => state.transactions,
  getTransactionsByType: filterByKey('transactions', 'type'),
  getCustomerTransactions: state => id => state.customerTransactions[id],
  getDailyTransactions: state => date => state.dailyTransactions[date],
  getDailyTransactionsByType: state => ({ date, type }) => (state.dailyTransactions[date] ? state.dailyTransactions[date].filter(tran => tran.type === type) : []),
}

const mutations = {
  setTransactions: set('transactions'),
  removeFromArray: (state, { name, key, value }) => {
    if (state[name]) {
      const removeIndex = state[name].map(item => item[key]).indexOf(value)
      state[name].splice(removeIndex, 1)
    }
  },
  extendArray: (state, { name, value }) => {
    state[name].push(value)
  },
  removeFromCustomerTransactions: (state, { cid, tid }) => {
    // not in use
    if (state.customerTransactions[cid]) {
      const removeIndex = state.customerTransactions[cid].map(item => item.tid).indexOf(tid)
      state.customerTransactions[cid].splice(removeIndex, 1)
    }
  },
  setHashKey: (state, {
    keyPath, key, data, date,
  }) => {
    let formattedKey = key
    if (date) formattedKey = Vue.moment(key).format('YYYY-MM-DD')
    Vue.set(state[keyPath], formattedKey, data)
  },
  setCustomerTransactions: (state, { cid, data }) => {
    Vue.set(state.customerTransactions, cid, data)
  },
  addCustomerTransactions: (state, { cid, transactions }) => {
    // note in use
    if (state.customerTransactions[cid]) {
      state.customerTransactions[cid] = [...transactions, ...state.customerTransactions[cid]]
    }
  },
}

const actions = {
  fetchCustomerTransactions: ({ state, commit, rootState }, { cid }) => new Promise((resolve, reject) => {
    const { sid } = rootState.users.currentStore
    if (cid && sid) {
      if (state.customerTransactions[cid]) {
        resolve(state.customerTransactions[cid])
      } else {
        transCollection.where('cid', '==', cid).where('sid', '==', sid).orderBy('createdAt').onSnapshot(snapshot => {
          const trans = []
          if (snapshot.empty) {
            commit('setCustomerTransactions', { cid, data: trans })
            resolve(trans)
          } else {
            snapshot.forEach(doc => {
              const d = doc.data()
              d.createdAt = d.createdAt.toDate()

              trans.push({ ...d, tid: doc.id })
            })

            commit('setCustomerTransactions', { cid, data: trans })
            resolve(trans)
          }
        }, err => {
          reject(err)
        })
      }
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  fetchDailyTransactions: ({ commit, state }, params) => new Promise((resolve, reject) => {
    const { sid, date } = params
    const formattedKey = Vue.moment(date).format('YYYY-MM-DD')
    const d = new Date(date)
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate())
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)

    if (sid && date && !state.dailyTransactions[formattedKey]) {
      transCollection
        .where('sid', '==', sid)
        .where('type', 'in', transactionTypes)
        .where('createdAt', '>=', start)
        .where('createdAt', '<', end)
        .orderBy('createdAt', 'desc')
        .onSnapshot(snapshot => {
          if (snapshot.empty) {
            commit('setHashKey', {
              keyPath: 'dailyTransactions', key: date, data: [], date: true,
            })
            resolve(null)
          } else {
            const transactions = []
            snapshot.forEach(doc => {
              transactions.push({ ...doc.data(), tid: doc.id, createdAt: doc.data().createdAt.toDate() })
            })

            commit('setHashKey', {
              keyPath: 'dailyTransactions', key: date, data: transactions, date: true,
            })
            resolve(transactions)
          }
        }, err => {
          reject(err)
        })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  fetchTransactions: ({ commit }, params) => new Promise((resolve, reject) => {
    const { sid, date } = params
    let { type } = params
    const d = new Date(date)
    const start = new Date(d.getFullYear(), d.getMonth(), d.getDate())
    const end = new Date(d.getFullYear(), d.getMonth(), d.getDate() + 1)
    if (type && type !== 'all') {
      type = (Array.isArray(type)) ? type : [type]
    } else {
      type = transactionTypes
    }

    if (sid && date && type) {
      transCollection
        .where('sid', '==', sid)
        .where('type', 'in', type)
        .where('createdAt', '>=', start)
        .where('createdAt', '<', end)
        .orderBy('createdAt', 'desc')
        .get()
        .then(snapshot => {
          if (snapshot.empty) {
            commit('setTransactions', [])
            resolve(null)
          } else {
            const transactions = []
            snapshot.forEach(doc => {
              transactions.push({ ...doc.data(), tid: doc.id, createdAt: doc.data().createdAt.toDate() })
            })

            commit('setTransactions', transactions)
            resolve(transactions)
          }
        })
        .catch(error => {
          reject(error)
        })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  createExpense: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, note, dateTime,
    } = params

    const createdAt = new Date(dateTime)
    const paid = +params.paid
    const userName = params.displayName

    if (sid && uid && paid && note && userName) {
      const transaction = {
        transactionType: 'expenses',
        type: 'expense',
        uid,
        userName,
        sid,
        paid,
        note,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      transCollection.add(transaction).then(snapshot => {
        transaction.createdAt = transaction.createdAt.toDate()
        commit('extendArray', { name: 'transactions', value: { ...transaction, tid: snapshot.id } })
        dispatch('logUser', {
          action: 'create_expense', actionId: snapshot.id, data: transaction, type: 'transactions',
        })
        resolve({ ...transaction, tid: snapshot.id })
      }).catch(error => {
        reject(error)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  deleteTransaction: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      tid, customer, type, principal, paymentReceived, allowance, penalty, days, paymentDuration, oldInterestRate,
    } = params

    const { debtInfo } = customer

    if (tid && ((type === 'created' && customer) || type !== 'created')) {
      const batch = firebase.db.batch()
      const transRef = transCollection.doc(tid)
      batch.delete(transRef)

      if (type === 'created') {
        const removeIndex = customer.loanHistories.map(item => item.tid).indexOf(tid)
        customer.loanHistories.splice(removeIndex, 1)

        if (customer.loan.principal > 0) {
          customer.loan.principal = +subtract(customer.loan.principal, principal)
          customer.loan.payable = +round(multiply(customer.loan.principal, divide(customer.loan.interestRate, 100)))
        }

        if (customer.loan.principal === 0) {
          customer.loan.dueDateTime = ''
          customer.loan.loanDateTime = ''
          customer.status = 'inactive'
        }

        const userRef = customersCollection.doc(customer.cid)
        batch.update(userRef, {
          status: customer.status,
          loan: { ...customer.loan },
          loanHistories: customer.loanHistories,
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else if (type === 'refinance') {
        if (oldInterestRate) {
          customer.loan.interestRate = oldInterestRate
        }

        const removeIndex = customer.loanHistories.map(item => item.tid).indexOf(tid)
        customer.loanHistories.splice(removeIndex, 1)
        if (customer.loan.dueDateTime && customer.loan.dueDateTime !== '') customer.loan.dueDateTime = new Date(Vue.moment(customer.loan.dueDateTime).subtract(days, 'd'))

        if (customer.loan.principal > 0) {
          customer.loan.principal = +subtract(customer.loan.principal, principal)
          customer.loan.payable = +round(multiply(customer.loan.principal, divide(customer.loan.interestRate, 100)))
        }

        if (customer.loan.principal === 0) {
          customer.loan.dueDateTime = ''
          customer.loan.loanDateTime = ''
          customer.status = 'inactive'
        }

        debtInfo.interestRate = customer.loan.interestRate

        const userRef = customersCollection.doc(customer.cid)
        batch.update(userRef, {
          status: customer.status,
          loan: { ...customer.loan },
          loanHistories: customer.loanHistories,
          'debtInfo.interestRate': customer.loan.interestRate,
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else if (type === 'interest') {
        customer.totalReceived.paymentReceived = +subtract(customer.totalReceived.paymentReceived, paymentReceived)
        if (allowance) {
          customer.totalReceived.allowanceReceived = +subtract(customer.totalReceived.allowanceReceived, allowance)
        }

        if (penalty) {
          customer.loan.penalty = +subtract(customer.loan.penalty, penalty)
        }

        if (customer.loan.dueDateTime && customer.loan.dueDateTime !== '') customer.loan.dueDateTime = new Date(Vue.moment(customer.loan.dueDateTime).subtract(paymentDuration, 'd'))

        const userRef = customersCollection.doc(customer.cid)
        batch.update(userRef, {
          loan: { ...customer.loan },
          totalReceived: { ...customer.totalReceived },
          loanHistories: [...customer.loanHistories],
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else if (type === 'closed') {
        dispatch('reCalculateCustomerTransactions', { cid: customer.cid, customer })
      } else if (type === 'penalty') {
        customer.totalReceived.penaltyReceived = +subtract(customer.totalReceived.penaltyReceived, paymentReceived)
        customer.loan.penalty = +add(customer.loan.penalty, paymentReceived)
        if (allowance) {
          customer.totalReceived.allowanceReceived = +subtract(customer.totalReceived.allowanceReceived, allowance)
        }

        const userRef = customersCollection.doc(customer.cid)
        batch.update(userRef, {
          loan: { ...customer.loan },
          totalReceived: { ...customer.totalReceived },
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else if (type === 'add_penalty') {
        const userRef = customersCollection.doc(customer.cid)
        batch.update(userRef, {
          loan: {
            ...customer.loan,
            penalty: +subtract(customer.loan.penalty, penalty),
          },
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else if (type === 'extend_date') {
        const userRef = customersCollection.doc(customer.cid)
        customer.totalReceived.allowanceReceived = +subtract(customer.totalReceived.allowanceReceived, allowance)
        if (customer.loan.dueDateTime && customer.loan.dueDateTime !== '' && days > 0) customer.loan.dueDateTime = new Date(Vue.moment(customer.loan.dueDateTime).subtract(days, 'd'))
        batch.update(userRef, {
          loan: { ...customer.loan },
          totalReceived: { ...customer.totalReceived },
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      } else if (type === 'fee') {
        const userRef = customersCollection.doc(customer.cid)
        customer.totalReceived.allowanceReceived = +subtract(customer.totalReceived.allowanceReceived, allowance)
        batch.update(userRef, {
          totalReceived: { ...customer.totalReceived },
          updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
        })
      }

      batch.commit().then(() => {
        if (!expenseTypes.includes(type)) {
          commit('replaceObjectFields', { name: 'currentCustomer', data: { loanHistories: customer.loanHistories, loan: customer.loan, debtInfo } })
          // commit('removeFromCustomerTransactions', { tid, cid: customer.cid })
        }
        commit('removeFromArray', { name: 'transactions', key: 'tid', value: tid })
        dispatch('logUser', { action: `delete_${type}`, actionId: tid, type: 'transactions' })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  addPenalty: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, dateTime, customer, penalty,
    } = params

    if (sid && uid && cid && dateTime && customer && penalty) {
      const userName = params.displayName
      const createdAt = new Date(dateTime)

      const transaction = {
        transactionType: 'loan',
        type: 'add_penalty',
        uid,
        userName,
        sid,
        cid,
        customerName: customer.basicInfo.fullName,
        dueDateTime: customer.loan.dueDateTime,
        loanDateTime: customer.loan.loanDateTime,
        penalty: +penalty,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (customer.loan.pid) {
        transaction.pid = customer.loan.pid
      }

      if (params.note) transaction.note = params.note

      const customerUpdate = {
        loan: {
          ...customer.loan,
          penalty: +add(customer.loan.penalty, penalty),
        },
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const tid = transRef.id

      batch.set(transRef, transaction)

      const customerRef = customersCollection.doc(cid)
      batch.update(customerRef, customerUpdate)

      batch.commit().then(() => {
        commit('replaceObjectFields', { name: 'currentCustomer', data: customerUpdate, check: { key: 'cid', value: cid } })
        transaction.tid = tid
        transaction.createdAt = transaction.createdAt.toDate()
        dispatch('logUser', {
          action: 'add_penalty', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  payPenalty: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, dateTime, customer, paid,
    } = params

    if (sid && uid && cid && dateTime && customer && paid) {
      const userName = params.displayName
      const createdAt = new Date(dateTime)

      const sumReceived = add(customer.totalReceived.paymentReceived, paid)

      const transaction = {
        transactionType: 'revenue',
        type: 'penalty',
        uid,
        userName,
        sid,
        cid,
        customerName: customer.basicInfo.fullName,
        principal: +customer.loan.principal,
        interestRate: +customer.loan.interestRate,
        paymentDuration: +customer.loan.paymentDuration,
        payable: +customer.loan.payable,
        dueDateTime: customer.loan.dueDateTime,
        loanDateTime: customer.loan.loanDateTime,
        paymentReceived: +paid,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (customer.loan.pid) {
        transaction.pid = customer.loan.pid
      }

      if (params.note) transaction.note = params.note

      const customerUpdate = {
        totalReceived: {
          ...customer.totalReceived,
          penaltyReceived: +sumReceived,
        },
        loan: {
          ...customer.loan,
        },
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }
      const leftover = +subtract(paid, customer.loan.penalty)
      if (leftover > 0) {
        customerUpdate.loan.penalty = 0
        customerUpdate.totalReceived.allowanceReceived = +add(customer.totalReceived.allowanceReceived, leftover)
        customerUpdate.totalReceived.penaltyReceived = +subtract(customerUpdate.totalReceived.paymentReceived, leftover)
        transaction.allowance = +leftover
      } else if (leftover < 0) {
        customerUpdate.loan.penalty = +subtract(customerUpdate.loan.penalty, paid)
      } else {
        customerUpdate.loan.penalty = 0
      }

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const tid = transRef.id

      batch.set(transRef, transaction)

      const customerRef = customersCollection.doc(cid)
      batch.update(customerRef, customerUpdate)

      batch.commit().then(() => {
        commit('replaceObjectFields', { name: 'currentCustomer', data: customerUpdate, check: { key: 'cid', value: cid } })
        transaction.tid = tid
        transaction.createdAt = transaction.createdAt.toDate()

        dispatch('logUser', {
          action: 'pay_penalty', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  payInterest: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, dateTime, customer, paid,
    } = params

    if (sid && uid && cid && dateTime && customer && paid) {
      const userName = params.displayName
      const createdAt = new Date(dateTime)

      const nextDateTime = new Date(Vue.moment(customer.loan.dueDateTime, 'YYYY-MM-DD HH:mm:ss').add(customer.loan.paymentDuration, 'd'))
      const nextDueDateTime = new Date(nextDateTime)
      const sumReceived = +add(customer.totalReceived.paymentReceived, paid)

      const transaction = {
        transactionType: 'revenue',
        type: 'interest',
        uid,
        userName,
        sid,
        cid,
        customerName: customer.basicInfo.fullName,
        principal: +customer.loan.principal,
        interestRate: +customer.loan.interestRate,
        paymentDuration: +customer.loan.paymentDuration,
        payable: +customer.loan.payable,
        dueDateTime: customer.loan.dueDateTime,
        loanDateTime: customer.loan.loanDateTime,
        paymentReceived: +paid,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (customer.loan.pid) {
        transaction.pid = customer.loan.pid
      }

      if (params.note) transaction.note = params.note

      const customerUpdate = {
        loan: {
          ...customer.loan,
          dueDateTime: nextDueDateTime,
        },
        totalReceived: {
          ...customer.totalReceived,
          paymentReceived: +sumReceived,
        },
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const tid = transRef.id

      const leftover = subtract(paid, customer.loan.payable)
      if (leftover > 0) {
        if (paid >= customer.loan.principal) {
          customerUpdate.status = 'closed'
          transaction.type = 'closed'
          transaction.dueDateTime = customer.loan.dueDateTime
          customerUpdate.loan.principal = 0
          customerUpdate.loan.payable = 0
          customerUpdate.loan.dueDateTime = ''
          customerUpdate.loan.penalty = 0
          customerUpdate.loanHistories = customer.loanHistories
          customerUpdate.loanHistories.push({
            tid, loanDateTime: createdAt, principal: customer.loan.principal, type: 'closed',
          })
          if (paid > customer.loan.principal) {
            const allow = +subtract(paid, customer.loan.principal)
            transaction.allowance = allow
            transaction.paymentReceived = customer.loan.principal
            customerUpdate.totalReceived.allowanceReceived = +add(customer.totalReceived.allowanceReceived, allow)
            customerUpdate.totalReceived.paymentReceived = +subtract(customerUpdate.totalReceived.paymentReceived, allow)
          }
        } else {
          transaction.allowance = +leftover
          transaction.paymentReceived = customer.loan.payable
          customerUpdate.totalReceived.allowanceReceived = +add(customer.totalReceived.allowanceReceived, leftover)
          customerUpdate.totalReceived.paymentReceived = +subtract(customerUpdate.totalReceived.paymentReceived, leftover)
        }
      } else if (leftover < 0) {
        transaction.penalty = +subtract(customer.loan.payable, paid)
        customerUpdate.loan.penalty = +subtract(customer.loan.payable, paid)
      }

      batch.set(transRef, transaction)

      const customerRef = customersCollection.doc(cid)
      batch.update(customerRef, customerUpdate)

      batch.commit().then(() => {
        commit('replaceObjectFields', { name: 'currentCustomer', data: customerUpdate, check: { key: 'cid', value: cid } })
        transaction.tid = tid
        transaction.createdAt = transaction.createdAt.toDate()

        dispatch('logUser', {
          action: 'pay_interest', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  createFee: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, customer, allowance, dateTime, note,
    } = params

    if (sid && uid && cid && customer && allowance > 0 && note) {
      const userName = params.displayName

      const transaction = {
        transactionType: 'revenue',
        type: 'fee',
        uid,
        userName,
        sid,
        cid,
        note,
        customerName: customer.basicInfo.fullName,
        paymentReceived: 0,
        allowance: +allowance,
        createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(new Date(dateTime)),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const { totalReceived } = customer

      totalReceived.allowanceReceived = +add(totalReceived.allowanceReceived, allowance)

      const customerUpdate = {
        totalReceived,
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const tid = transRef.id

      batch.set(transRef, transaction)

      const customerRef = customersCollection.doc(cid)
      batch.update(customerRef, customerUpdate)

      batch.commit().then(() => {
        commit('replaceObjectFields', { name: 'currentCustomer', data: { totalReceived: customerUpdate.totalReceived } })
        transaction.tid = tid
        transaction.createdAt = new Date()
        // commit('addCustomerTransactions', { cid, transactions: [transaction] })
        dispatch('logUser', {
          action: 'add_fee', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  extendLoanDueDate: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, customer, days, allowance, dateTime,
    } = params

    if (sid && uid && cid && days >= 0 && customer && allowance >= 0) {
      const userName = params.displayName

      const transaction = {
        transactionType: 'revenue',
        type: 'extend_date',
        uid,
        userName,
        sid,
        cid,
        customerName: customer.basicInfo.fullName,
        days,
        paymentReceived: 0,
        allowance: +allowance,
        createdAt: firebase.firebase.firestore.FieldValue.serverTimestamp(new Date(dateTime)),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (customer.loan.pid) {
        transaction.pid = customer.loan.pid
      }

      transaction.note = `[${Vue.moment(customer.loan.dueDateTime).format('YYYY-MM-DD')} + ${days}]`

      if (params.note) transaction.note = `${transaction.note} ${params.note}`

      const dueDateTime = new Date(Vue.moment(customer.loan.dueDateTime, 'YYYY-MM-DD HH:mm:ss').add(days, 'd'))
      const { totalReceived } = customer

      totalReceived.allowanceReceived = +add(totalReceived.allowanceReceived, allowance)

      const customerUpdate = {
        loan: {
          ...customer.loan,
          dueDateTime,
        },
        totalReceived,
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const tid = transRef.id

      batch.set(transRef, transaction)

      const customerRef = customersCollection.doc(cid)
      batch.update(customerRef, customerUpdate)

      batch.commit().then(() => {
        commit('replaceObjectFields', { name: 'currentCustomer', data: { loan: customerUpdate.loan, totalReceived: customerUpdate.totalReceived } })
        transaction.tid = tid
        transaction.createdAt = new Date()
        // commit('addCustomerTransactions', { cid, transactions: [transaction] })
        dispatch('logUser', {
          action: 'extend_due_date', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  transactionsReport: (ctx, params) => new Promise((resolve, reject) => {
    const { sid, date } = params
    if (sid && date) {
      const startDateTime = new Date(Vue.moment(date, 'YYYY-MM-DD HH:mm').subtract(31, 'd'))
      const endDateTime = new Date(Vue.moment(date, 'YYYY-MM-DD HH:mm').add(1, 'd'))

      transCollection
        .where('sid', '==', sid)
        .where('type', '==', 'created')
        .where('createdAt', '>=', startDateTime)
        .where('createdAt', '<', endDateTime)
        .orderBy('createdAt', 'desc')
        .get()
        .then(trans => {
          const transactions = []
          if (!trans.empty) {
            const count = {}
            trans.forEach(t => {
              const data = t.data()
              const d = Vue.moment(data.createdAt.toDate()).format('YYYY-MM-DD')
              count[d] ||= {}
              count[d][data.userName] ||= { count: 0, amount: 0 }
              count[d][data.userName].count += 1
              count[d][data.userName].amount = +add(count[d][data.userName].amount, data.principal)
            })
            Object.entries(count).forEach(([key, value]) => {
              Object.entries(value).forEach(([k, v]) => {
                transactions.push({
                  date: key,
                  userName: k,
                  count: v.count,
                  amount: v.amount,
                })
              })
            })
          }
          resolve(transactions)
        })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  createLoan: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, dateTime, customer, loanType, firstPayment, deductToday,
    } = params

    let { interestRate, paymentDuration } = params
    const percentageInterestRate = interestRate

    // loan date and due date time should be updated
    const userName = params.displayName
    const createdAt = new Date(dateTime)
    let principal = +params.amount
    const amount = +params.amount
    paymentDuration ||= +customer.debtInfo.paymentDuration
    const firstDuration = (deductToday) ? +subtract(paymentDuration, 1) : paymentDuration
    const interestDateTime = new Date(Vue.moment(dateTime, 'YYYY-MM-DD HH:mm:ss').add(1, 'm'))
    const dueDateTime = new Date(Vue.moment(dateTime, 'YYYY-MM-DD HH:mm:ss').add(firstDuration, 'd'))
    interestRate = (interestRate) ? +divide(interestRate, 100) : +divide(customer.debtInfo.interestRate, 100)

    const payable = +round(multiply(principal, interestRate))
    if (loanType) {
      // do something
    }

    if (customer.loan.principal) {
      principal = +add(principal, customer.loan.principal)
    }

    const totalPayable = +round(multiply(principal, interestRate))
    if (sid && uid && principal && paymentDuration && interestRate && userName && customer) {
      const transaction = {
        transactionType: 'expenses',
        type: 'created',
        uid,
        userName,
        sid,
        cid,
        loanType,
        customerName: customer.basicInfo.fullName,
        principal: amount,
        paid: amount,
        paymentDuration: firstDuration,
        interestRate: +percentageInterestRate,
        payable: +payable,
        loanDateTime: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const interest = {
        transactionType: 'revenue',
        type: 'interest',
        uid,
        userName,
        sid,
        cid,
        dueDateTime: firebase.firebase.firestore.Timestamp.fromDate(dueDateTime),
        paymentReceived: +payable,
        customerName: customer.basicInfo.fullName,
        principal: amount,
        paymentDuration: 0,
        interestRate: +percentageInterestRate,
        payable: +payable,
        loanDateTime: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(interestDateTime),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const intransRef = transCollection.doc()
      const tid = transRef.id
      const intid = intransRef.id

      const { loan, loanHistories, totalReceived } = customer
      loan.loanDateTime ||= createdAt
      loan.principal = +principal
      loan.interestRate = +percentageInterestRate
      loan.paymentDuration = +paymentDuration
      loan.payable = +totalPayable
      loan.dueDateTime = dueDateTime
      loan.pid = tid

      loanHistories.push({
        tid, loanDateTime: createdAt, principal: amount, type: 'created',
      })

      batch.set(transRef, transaction)

      if (firstPayment) {
        batch.set(intransRef, interest)
        totalReceived.paymentReceived = +add(customer.totalReceived.paymentReceived, payable)
      }

      const userRef = customersCollection.doc(cid)
      batch.update(userRef, {
        status: 'processing',
        loan,
        loanHistories,
        totalReceived,
        'debtInfo.interestRate': +percentageInterestRate,
        'debtInfo.paymentDuration': +paymentDuration,
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      })

      batch.commit().then(() => {
        const debtInfo = {
          ...customer.debtInfo,
          interestRate: +percentageInterestRate,
          paymentDuration: +paymentDuration,
        }
        commit('replaceObjectFields', {
          name: 'currentCustomer',
          data: {
            loan, loanHistories, totalReceived, debtInfo,
          },
        })
        transaction.tid = tid
        transaction.createdAt = transaction.createdAt.toDate()
        interest.tid = intid
        interest.createdAt = interest.createdAt.toDate()
        dispatch('logUser', {
          action: 'create_loan', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  refinance: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
    const {
      sid, uid, cid, dateTime, customer, days, firstPayment,
    } = params
    let { paymentDuration, interestRate } = params

    // loan date and due date time should be updated
    const userName = params.displayName
    const createdAt = new Date(dateTime)
    let principal = +params.amount
    const amount = +params.amount
    paymentDuration ||= +customer.debtInfo.paymentDuration
    const interestDateTime = new Date(Vue.moment(dateTime, 'YYYY-MM-DD HH:mm:ss').add(1, 'm'))
    const dueDateTime = new Date(Vue.moment(customer.loan.dueDateTime, 'YYYY-MM-DD HH:mm:ss').add(days, 'd'))
    const percentageInterestRate = (interestRate) ? +interestRate : +customer.debtInfo.interestRate
    interestRate = (interestRate) ? +divide(interestRate, 100) : +divide(customer.debtInfo.interestRate, 100)

    const payable = +round(multiply(principal, interestRate))

    if (customer.loan.principal) {
      principal = +add(principal, customer.loan.principal)
    }

    const totalPayable = +round(multiply(principal, interestRate))
    if (sid && uid && principal && days >= 0 && interestRate && userName && customer) {
      const transaction = {
        transactionType: 'expenses',
        type: 'refinance',
        uid,
        userName,
        sid,
        cid,
        customerName: customer.basicInfo.fullName,
        principal: amount,
        paid: amount,
        paymentDuration,
        days,
        interestRate: percentageInterestRate,
        oldInterestRate: customer.loan.interestRate,
        payable: +payable,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (days > 0) transaction.note = `[${Vue.moment(customer.loan.dueDateTime).format('YYYY-MM-DD')} + ${days}]`

      const interest = {
        transactionType: 'revenue',
        type: 'interest',
        uid,
        userName,
        sid,
        cid,
        dueDateTime: firebase.firebase.firestore.Timestamp.fromDate(createdAt),
        paymentReceived: (firstPayment === 'total') ? +totalPayable : +payable,
        customerName: customer.basicInfo.fullName,
        principal: (firstPayment === 'total') ? principal : amount,
        paymentDuration: 0,
        interestRate: percentageInterestRate,
        payable: (firstPayment === 'total') ? +totalPayable : +payable,
        createdAt: firebase.firebase.firestore.Timestamp.fromDate(interestDateTime),
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      }

      if (customer.loan.pid) {
        transaction.pid = customer.loan.pid
        interest.pid = customer.loan.pid
      }

      const { loan, loanHistories, totalReceived } = customer
      loan.loanDateTime ||= createdAt
      loan.principal = +principal
      loan.interestRate = percentageInterestRate
      loan.paymentDuration = +paymentDuration
      loan.payable = +totalPayable
      loan.dueDateTime = dueDateTime

      const batch = firebase.db.batch()
      const transRef = transCollection.doc()
      const intransRef = transCollection.doc()
      const tid = transRef.id

      loanHistories.push({
        tid, loanDateTime: createdAt, principal: amount, type: 'refinance',
      })

      batch.set(transRef, transaction)

      if (['total', 'current'].includes(firstPayment)) {
        batch.set(intransRef, interest)
        totalReceived.paymentReceived = +add(customer.totalReceived.paymentReceived, interest.paymentReceived)
      }

      const debtInfo = {
        ...customer.debtInfo,
        interestRate: +percentageInterestRate,
        paymentDuration: +paymentDuration,
      }

      const userRef = customersCollection.doc(cid)
      batch.update(userRef, {
        status: 'processing',
        loan,
        loanHistories,
        totalReceived,
        debtInfo,
        updatedAt: firebase.firebase.firestore.FieldValue.serverTimestamp(),
      })

      batch.commit().then(() => {
        commit('replaceObjectFields', {
          name: 'currentCustomer',
          data: {
            loan, loanHistories, totalReceived, debtInfo,
          },
        })
        transaction.tid = tid
        transaction.createdAt = transaction.createdAt.toDate()
        dispatch('logUser', {
          action: 'refinance', actionId: tid, data: transaction, type: 'transactions',
        })
        resolve()
      }).catch(err => {
        reject(err)
      })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
  reCalculateCustomerTransactions: ({ commit, dispatch }, { cid, customer }) => new Promise((resolve, reject) => {
    if (cid && customer) {
      let { loan } = customer
      loan = {
        ...loan,
        loanDateTime: '',
        interestRate: 0,
        principal: 0,
        payable: 0,
        dueDateTime: '',
        penalty: 0,
      }
      const totalReceived = {
        paymentReceived: 0,
        penaltyReceived: 0,
        allowanceReceived: 0,
      }
      const { debtInfo } = customer

      const loanHistories = []
      transCollection.where('cid', '==', cid).where('sid', '==', customer.sid).orderBy('createdAt').get()
        .then(trans => {
          if (trans.empty) {
            customersCollection.doc(cid).update({ loan, totalReceived })
            commit('replaceObjectFields', { name: 'currentCustomer', data: { loan, loanHistories, totalReceived } })
          } else {
            trans.forEach(doc => {
              const transaction = doc.data()
              transaction.createdAt = transaction.createdAt.toDate()
              // 'created', 'interest', 'add_penalty', 'extend_date', penalty, closed`
              if (transaction.type === 'created') {
                const interestRate = +divide(transaction.interestRate, 100)
                loan.principal = +add(loan.principal, transaction.principal)
                loan.interestRate = transaction.interestRate
                loan.loanDateTime = transaction.createdAt
                loan.payable = +round(multiply(loan.principal, interestRate))
                loan.dueDateTime = new Date(Vue.moment(transaction.createdAt).add(transaction.paymentDuration, 'd'))
                debtInfo.interestRate = transaction.interestRate
                loanHistories.push({
                  tid: doc.id, loanDateTime: transaction.createdAt, principal: transaction.principal, type: 'created',
                })
              } else if (transaction.type === 'refinance') {
                const interestRate = +divide(transaction.interestRate, 100)
                loan.interestRate = transaction.interestRate
                loan.principal = +add(loan.principal, transaction.principal)
                loan.payable = +round(multiply(loan.principal, interestRate))
                loan.dueDateTime = new Date(Vue.moment(loan.dueDateTime).add(transaction.days, 'd'))
                debtInfo.interestRate = transaction.oldInterestRate
                debtInfo.paymentDuration = transaction.paymentDuration
                loanHistories.push({
                  tid: doc.id, loanDateTime: transaction.createdAt, principal: transaction.principal, type: 'refinance',
                })
              } else if (transaction.type === 'interest' || transaction.type === 'penalty') {
                if (transaction.type === 'interest') {
                  if (loan.dueDateTime && loan.dueDateTime !== '') loan.dueDateTime = new Date(Vue.moment(loan.dueDateTime).add(transaction.paymentDuration, 'd'))
                  totalReceived.paymentReceived = +add(totalReceived.paymentReceived, transaction.paymentReceived)
                } else {
                  totalReceived.penaltyReceived = +add(totalReceived.penaltyReceived, transaction.paymentReceived)
                  loan.penalty = +subtract(loan.penalty, transaction.paymentReceived)
                }
                if (transaction.allowance) totalReceived.allowanceReceived = +add(totalReceived.allowanceReceived, transaction.allowance)
                if (transaction.penalty) loan.penalty = transaction.penalty
              } else if (transaction.type === 'extend_date') {
                loan.dueDateTime = new Date(Vue.moment(loan.dueDateTime).add(transaction.days, 'd'))
                totalReceived.allowanceReceived = +add(totalReceived.allowanceReceived, transaction.allowance)
              } else if (transaction.type === 'fee') {
                totalReceived.allowanceReceived = +add(totalReceived.allowanceReceived, transaction.allowance)
              } else if (transaction.type === 'closed') {
                loan.principal = 0
                loan.payable = 0
                loan.dueDateTime = ''
                totalReceived.paymentReceived = +add(totalReceived.paymentReceived, transaction.paymentReceived)
                if (transaction.allowance) totalReceived.allowanceReceived = +add(totalReceived.allowanceReceived, transaction.allowance)
                loanHistories.push({
                  tid: doc.id, loanDateTime: transaction.createdAt, principal: transaction.principal, type: 'closed',
                })
              }
            })
            customersCollection.doc(cid).update({
              loan, totalReceived, loanHistories, debtInfo,
            }).then(() => {
              dispatch('logUser', {
                action: 're_calculate_data', actionId: cid, type: 'transactions',
              })
              commit('replaceObjectFields', { name: 'currentCustomer', data: { loan, loanHistories, totalReceived } })
            })
          }
        })
    } else {
      reject(new Error('Missing Params'))
    }
  }),
}

export default {
  state,
  mutations,
  getters,
  actions,
}
